import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const ContactUs = () => {
    const form = useRef();
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const badRequest = (texto) => {
        toast.error(texto, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const goodRequest = () => {
        toast.success("Mensaje enviado, le responderemos a la brevedad.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const sendEmail = (e) => {
        e.preventDefault();

        if (email.length > 0 && message.length > 9) {
            emailjs
                .sendForm(
                    "service_ybyc45g",
                    "template_hton2ar",
                    form.current,
                    "ErzDqZ5NrvYES3Jzr"
                )
                .then(
                    (result) => {
                        goodRequest();
                        setEmail("");
                        setMessage("");
                    },
                    (error) => {
                        console.log(error.text);
                    }
                );
        } else if (email.length === 0) {
            badRequest("Completar con su mail");
        } else if (message.length <= 9) {
            badRequest("El texto debe tener minimo 10 caracteres");
        }
    };

    return (
        <form
            className="flex flex-col justify-center items-center "
            ref={form}
            onSubmit={sendEmail}
        >
            <input
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                placeholder="Email"
                type="email"
                name="user_email"
                className="w-[90vw] max-w-[500px] p-2 outline-none text-lg sm:font-medium rounded bg-[#29335C] text-white"
            />

            <textarea
                placeholder="Mensaje"
                className="rounded flex w-[90vw] max-w-[500px] h-44 sm:h-52 resize-none mt-3 p-2 outline-none text-lg sm:font-medium bg-[#29335C] text-white"
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                name="message"
            />
            <input
                className="mt-3 w-[90vw] max-w-[500px] bg-green-600 p-3 font-medium text-lg rounded cursor-pointer hover:bg-gree"
                type="submit"
                value="Enviar"
            />
            <ToastContainer />
        </form>
    );
};
