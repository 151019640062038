import Pirelli from "./assets/pirelli.png";
import Dayco from "./assets/Dayco.png";
import Dunlop from "./assets/dunlop.png";

export default function Proveedores() {
    return (
        <section className="w-full md:px-[5%] pb-10">
            <div className="flex w-full flex-col justify-center items-center sm:flex-row">
                <img
                    src={Pirelli}
                    alt="Pirelli"
                    loading="lazy"
                    className=" w-60 sm:w-44 md:w-50 lg:w-60"
                />
                <img
                    src={Dayco}
                    alt="Dayco"
                    loading="lazy"
                    className="w-60 sm:w-44 md:w-50 sm:mx-5 lg:mx-16 lg:w-60"
                />
                <img
                    src={Dunlop}
                    alt="Dunlop"
                    loading="lazy"
                    className="w-60 sm:w-44 md:w-50 lg:w-60"
                />
            </div>
        </section>
    );
}
