import React from "react";

import { Producto } from "./producto";

export const Conjunto = () => {
    return (
        <>
            <Producto
                img={"/mangueraAutomotor.png"}
                title={"Manguera automotor"}
            />
            <Producto
                img={"/mangueraIndustrial.png"}
                title={"Mangueras industriales"}
            />
            <Producto
                img={"/burleteEsponjoso.png"}
                title={"Burlete esponjoso"}
            />
            <Producto img={"/burleteDuro.png"} title={"Burlete duro"} />
            <Producto img={"/gomasVarias.jpg"} title={"Varios de goma"} />
            <Producto
                img={"/correaIndustrial.jpeg"}
                title={"Correas industriales"}
            />
            <Producto img={"/pisoGoma.png"} title={"Piso de goma"} />
            <Producto img={"/planchaGoma.jpeg"} title={"Plancha de goma"} />
        </>
    );
};
