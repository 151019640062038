import ButtonHome from "../Home/buttonHome/Button";

import foto from "./assets/nosotros.jpeg";

import "../Home/home.css";

export default function Nosotros() {
    return (
        <section
            id="nosotros"
            className="home flex-col md:flex-row md:justify-center items-center relative md:px-[5%]"
        >
            <div className="texto-home w-[100%] px-10 sm:px-14 pt-5 pb-10 md:p-0 md:pr-14 lg:pr-30  md:mr-[2.5%]">
                <h2 className="font-bold text-5xl mb-5 md:mr-auto sm:text-6xl mt-5 md:mt-0 text-zinc-950">
                    Nosotros
                </h2>
                <p className="text-lg md:text-base lg:text-lg md:text-left">
                    Fundada en{" "}
                    <span className="text-[#29335C] font-semibold">1973</span>,
                    dedicados a la venta y distribución de{" "}
                    <span className="text-[#29335C] font-semibold">
                        productos de goma
                    </span>
                    . Fuimos distribuidores de{" "}
                    <span className="text-[#29335C] font-semibold">
                        INDUSTRIAS PIRELLI SAIC
                    </span>{" "}
                    hasta su permanencia en la Argentina, a posterior pasamos a
                    formar parte de la distribución de{" "}
                    <span className="text-[#29335C] font-semibold">
                        DUNLOP ARGENTINA SA
                    </span>
                    .
                </p>
                <a
                    href="https://wa.link/p9hkjb"
                    rel="noreferrer"
                    target="_blank"
                    className="md:mr-auto"
                >
                    <ButtonHome className="btn" text={"Contactar"} />
                </a>
            </div>
            <div className="w-[90%] mb-10 md:mb-0 max-w-[500px]">
                <div className="w-full h-full rounded-[5px]">
                    <img
                        src={foto}
                        alt="Foto de articulos de goma"
                        loading="lazy"
                        className="rounded-[5px] w-full h-full imgNosotros"
                    />
                </div>
            </div>
            <div className="absolute -z-10 bottom-0 w-full">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    height="100"
                    preserveAspectRatio="none"
                    className="w-full"
                    viewBox="0 0 1440 300"
                >
                    <g mask='url("#SvgjsMask1026")' fill="none">
                        <path
                            d="M 0,56 C 96,81.4 288,169.8 480,183 C 672,196.2 768,105.4 960,122 C 1152,138.6 1344,237.2 1440,266L1440 300L0 300z"
                            fill="rgba(41, 51, 92, 1)"
                        ></path>
                    </g>
                    <defs>
                        <mask id="SvgjsMask1026">
                            <rect
                                width="1440"
                                height="300"
                                fill="#ffffff"
                            ></rect>
                        </mask>
                    </defs>
                </svg>
            </div>
        </section>
    );
}
