import "./home.css";
import ButtonHome from "./buttonHome/Button";
import Gallery from "./Gallery/Gallery";

export default function Home(texto) {
    return (
        <section
            id="inicio"
            className="home flex-col md:flex-row md:justify-center items-center relative md:px-[5%] "
        >
            <div className="texto-home px-10 sm:px-14 pt-5 pb-10 md:p-0 lg:pr-30 md:mr-[2.5%]">
                <h1 className="font-bold text-5xl mb-5 md:mr-auto sm:text-6xl mt-5 md:mt-0 text-zinc-950">
                    CP Goma
                </h1>
                <p className="text-lg md:text-base lg:text-lg md:text-left">
                    Empresa con{" "}
                    <span className="text-[#29335C] font-semibold">
                        +50 años{" "}
                    </span>
                    de experiencia dedicados a la comercializacion de{" "}
                    <span className="text-[#29335C] font-semibold">
                        articulos de goma
                    </span>{" "}
                    para la industria del{" "}
                    <span className="text-[#29335C] font-semibold">
                        automotor
                    </span>{" "}
                    y para el{" "}
                    <span className="text-[#29335C] font-semibold">hogar</span>.
                </p>
                <a
                    href="https://cpgoma.mercadoshops.com.ar/"
                    rel="noreferrer"
                    target="_blank"
                    className="md:mr-auto"
                >
                    <ButtonHome className="btn" text={"Ir a la tienda"} />
                </a>
            </div>
            <div className="w-[90%] mb-10 md:mb-0 max-w-[500px]">
                <Gallery />
            </div>
            <div className="absolute -z-10 bottom-0 w-full">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    
                    height="150"
                    className="w-full"
                    preserveAspectRatio="none"
                    viewBox="0 0 1440 300"
                >
                    <g mask='url("#SvgjsMask1013")' fill="none">
                        <path
                            d="M 0,83 C 144,99 432,163 720,163 C 1008,163 1296,99 1440,83L1440 300L0 300z"
                            fill="rgba(41, 51, 92, 1)"
                        ></path>
                    </g>
                    <defs>
                        <mask id="SvgjsMask1013">
                            <rect
                                width="1440"
                                height="300"
                                fill="#ffffff"
                            ></rect>
                        </mask>
                    </defs>
                </svg>
            </div>
        </section>
    );
}
