import { ContactUs } from "./Form/Form";
import { FaPhone, FaLocationDot } from "react-icons/fa6";

export default function Contact() {
    return (
        <section
            id="contacto"
            className="w-full bg-white -translate-y-3 flex flex-col pt-24 pb-10 justify-center items-center md:px-[5%]"
        >
            <h3 className="font-bold text-5xl mb-10 lg:text-6xl mt-5 md:mt-0 text-zinc-950">
                Contactanos
            </h3>
            <div className="flex flex-col lg:flex-row bg-white justify-center items-center">
                <ContactUs />
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6567.529958481843!2d-58.558432999999994!3d-34.610104!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb9ebb008b6ed%3A0x8d20ddb3160dd5f3!2sCP%20Goma!5e0!3m2!1ses-419!2sar!4v1717709045887!5m2!1ses-419!2sar"
                    title="CP Goma"
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    className="w-[90vw] max-w-[500px] h-[90vw] max-h-[300px] lg:max-w-[300px] lg:max-h-[325px] mt-10 lg:mt-0 lg:ml-5 rounded"
                ></iframe>
            </div>
            <div className="mt-10 flex flex-col sm:flex-row">
                <span className="flex justify-center items-center text-xl sm:mr-10 font-medium">
                    <FaPhone className="text-green-600 mr-2 text-lg" />
                    11 3379-5587
                </span>
                <span className="flex justify-center items-center  mt-5 sm:mt-0 text-xl font-medium">
                    <FaLocationDot className="mr-2 text-lg text-blue-800" />
                    Av. Mitre 4461, Caseros
                </span>
            </div>
        </section>
    );
}
